import Vue from 'vue';
import TYPES from '@/types';

// Domain
import { StateManager }
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class EditCustomGoalPlanSuccessViewModel {
  @Inject(TYPES.EDIT_CUSTOM_GOAL_PLAN_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-custom-goal-plan.custom_goal_plan_success';

  readonly view: Vue;

  readonly state = this.state_manager.state.define_by;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  closeModal = () => {
    window.location.href = '/goals-dashboard';
  }
}
