



























































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import EditCustomGoalPlanSuccessViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-custom-goal-plan/edit-custom-goal-plan-success-view-model';

@Component({ name: 'EditCustomGoalPlanSuccess' })
export default class EditCustomGoalPlanSuccess extends Vue {
  @Prop({ type: String, required: true })
  customGoalName!: string;

  edit_custom_goal_success_view_model = Vue.observable(
    new EditCustomGoalPlanSuccessViewModel(this),
  );
}
